import React, { createContext, useCallback, useContext, useState } from 'react';
import { Close } from '@ghosts-inc/design-system/icons';
import { AlertProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';

const DEFAULT_AUTO_HIDE_DURATION_MS = 4000;

/**
 * Snackbar title, text, and severity.
 */
type ShowSnackbarProps = {
  title: string;
  text?: string | null;
  severity: AlertProps['severity'];
  isFullWidth?: boolean;
  autoHideDurationMs?: number;
  /** When this prop is set to true, the snackbar will stay on screen until the
   * auto-hide duration finishes or the user manually closes the snackbar
   * https://mui.com/base-ui/react-click-away-listener/components-api/#click-away-listener */
  disableClickawayListener?: boolean;
};

/**
 * Initialize with empty value.
 */
const SnackbarContext = createContext<{
  showSnackbar: (args: ShowSnackbarProps) => void;
}>({
  showSnackbar: () => {},
});

// Used for fullWidth snackbar
const getSnackbarColor = (severity: AlertProps['severity']) => {
  switch (severity) {
    case 'alertSuccess':
      return 'success.main';
    case 'alertError':
      return 'error.main';
    default:
      return 'info.blue';
  }
};

/**
 * Returns a function that can be called to show the global snackbar, with updated values
 * specified by the caller.
 */
export const useSnackbar = () => {
  const { showSnackbar } = useContext(SnackbarContext);
  const memoizedShowSnackbar = useCallback(showSnackbar, [showSnackbar]);
  // Show snackbar with values + severity specified by the caller
  return { showSnackbar: memoizedShowSnackbar };
};

/**
 * Used at the top level of the app to provide the global snackbar.
 */
export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  // State has to be an object, or you get race conditions between various updates happening. Things run out of order.
  const [state, setState] = useState<ShowSnackbarProps & { snackbarOpen: boolean }>({
    snackbarOpen: false,
    title: '',
    severity: 'alertSuccess',
    isFullWidth: false,
    autoHideDurationMs: DEFAULT_AUTO_HIDE_DURATION_MS,
    disableClickawayListener: false,
  });

  // This is the function that actually updates state when used by the various snackbar users.
  const setSnackbarValues = useCallback(
    ({
      title: callbackTitle,
      text: callbackText,
      severity: callbackSeverity,
      isFullWidth: callbackIsFullWidth,
      autoHideDurationMs: callbackAutoHideDurationMs,
      disableClickawayListener: callbackDisableClickawayListener,
    }: ShowSnackbarProps) =>
      setState({
        snackbarOpen: true,
        title: callbackTitle,
        text: callbackText,
        severity: callbackSeverity,
        isFullWidth: callbackIsFullWidth ?? false,
        autoHideDurationMs: callbackAutoHideDurationMs ?? DEFAULT_AUTO_HIDE_DURATION_MS,
        disableClickawayListener: callbackDisableClickawayListener ?? false,
      }),
    [],
  );

  const closeSnackbar = () => {
    setState((prevState) => {
      return {
        ...prevState,
        snackbarOpen: false,
      };
    });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar: setSnackbarValues }}>
      {children}
      <Snackbar
        open={state.snackbarOpen}
        autoHideDuration={state.autoHideDurationMs}
        onClose={closeSnackbar}
        ClickAwayListenerProps={{
          // If disableClickawayListener is true, pass false to these props to disable the listener,
          // otherwise let them use their defaults
          mouseEvent: state.disableClickawayListener ? false : undefined,
          touchEvent: state.disableClickawayListener ? false : undefined,
        }}
      >
        <Alert
          sx={state.isFullWidth ? { width: '100%' } : {}}
          onClose={closeSnackbar}
          severity={state.severity}
          action={<Close onClick={closeSnackbar} />}
        >
          <AlertTitle>{state.title}</AlertTitle>
          {state.text}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
